var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container decisionReserved" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isShowEdit,
              expression: "!isShowEdit"
            }
          ],
          staticClass: "part"
        },
        [
          _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("预留单号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.reserveId,
                      callback: function($$v) {
                        _vm.reserveId = $$v
                      },
                      expression: "reserveId"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("业务编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.saleOrderId,
                      callback: function($$v) {
                        _vm.saleOrderId = $$v
                      },
                      expression: "saleOrderId"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.materialCode,
                      callback: function($$v) {
                        _vm.materialCode = $$v
                      },
                      expression: "materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("预留类型：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.searchHandle },
                      model: {
                        value: _vm.reserveType,
                        callback: function($$v) {
                          _vm.reserveType = $$v
                        },
                        expression: "reserveType"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "全部", value: "" }
                      }),
                      _vm._l(_vm.reserveTypeList, function(item) {
                        return _c("el-option", {
                          key: item.dictItemValue,
                          attrs: {
                            label: item.dictItemName,
                            value: item.dictItemValue
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("客户名称：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10 mb10",
                      attrs: {
                        filterable: "",
                        placeholder: "请选择",
                        "filter-method": _vm.userFilter
                      },
                      on: { change: _vm.addValueOptions },
                      model: {
                        value: _vm.customerValue,
                        callback: function($$v) {
                          _vm.customerValue = $$v
                        },
                        expression: "customerValue"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.customerList, function(item) {
                        return _c("el-option", {
                          key: item.customerId,
                          attrs: {
                            label: item.customerNameCh,
                            value: item.customerId
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("预留状态：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10 mb10",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.searchHandle },
                      model: {
                        value: _vm.reserveStatusName,
                        callback: function($$v) {
                          _vm.reserveStatusName = $$v
                        },
                        expression: "reserveStatusName"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.ReserveReleaselist, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { label: item.name, value: item.index }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                  _c("el-date-picker", {
                    staticClass: "mr10",
                    attrs: {
                      title: _vm.valuedata,
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    on: { change: _vm.dateValChange },
                    model: {
                      value: _vm.valuedata,
                      callback: function($$v) {
                        _vm.valuedata = $$v
                      },
                      expression: "valuedata"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "actions_btn_wrap down t_right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.excelDownload }
                  },
                  [_vm._v("导出Excel")]
                ),
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                    circle: "",
                    title: "搜索"
                  },
                  on: { click: _vm.searchHandle }
                }),
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    circle: "",
                    title: "添加"
                  },
                  on: { click: _vm.addHandle }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "table_part clearfix" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "subnav_wrap hidden",
                  on: { "tab-click": _vm.switchTabHandle },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.subNavList, function(item) {
                  return _c(
                    "el-tab-pane",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading"
                        }
                      ],
                      key: item.value,
                      attrs: { label: item.label, name: item.value }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          refInFor: true,
                          staticClass: "table_list",
                          attrs: {
                            data: _vm.requestList,
                            "row-key": "id",
                            "expand-row-keys": _vm.expands,
                            border: "",
                            fit: "",
                            stripe: ""
                          },
                          on: {
                            "row-click": _vm.rowClick,
                            select: _vm.selectHandle,
                            "expand-change": _vm.expandChange,
                            "select-all": _vm.selectAllHandle
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "actions_wrap mb20 clearfix"
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "fr",
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.childExcelDownload(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("导出Excel")]
                                          ),
                                          scope.row.auditStatus == "Draft" ||
                                          scope.row.auditStatus == "Rejected"
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass: "fr mr10",
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editHandle(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("更新导入Excel")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-table",
                                        {
                                          ref:
                                            "childMultipleTable_" +
                                            scope.row.id,
                                          refInFor: true,
                                          staticClass: "table_list",
                                          attrs: {
                                            data: scope.row.reserveItemList,
                                            border: "",
                                            fit: ""
                                          },
                                          on: {
                                            select: function(selection, row) {
                                              _vm.childSelectHandle(
                                                selection,
                                                row,
                                                scope.row
                                              )
                                            },
                                            "select-all": function(selection) {
                                              _vm.childSelectAllHandle(
                                                selection,
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: { fixed: "", label: "行号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(_vm._s(row.sn))
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              fixed: "",
                                              label: "产品编号"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        _vm._s(row.productCode)
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "物料编号",
                                              "min-width": "180",
                                              "class-name": "marpno_wuliao",
                                              "show-overflow-tooltip": true
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        _vm._s(row.materialCode)
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预留库位" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.subInventoryName
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预留时间" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.reserveBeginTime
                                                        ) +
                                                          "—" +
                                                          _vm._s(
                                                            row.reserveEndTime
                                                          )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预留状态" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      scope.row.auditStatus ==
                                                      "Approved"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              attrs: { id: "" }
                                                            },
                                                            [
                                                              row.reserveStatus ==
                                                              "Reserve"
                                                                ? _c(
                                                                    "em",
                                                                    {
                                                                      attrs: {
                                                                        title:
                                                                          "预留"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "预留"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              row.reserveStatus ==
                                                              "Release"
                                                                ? _c(
                                                                    "em",
                                                                    {
                                                                      attrs: {
                                                                        title:
                                                                          "解除"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "解除"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "工厂包号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.factoryBatchNumber
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预留ID" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        _vm._s(row.batchNo)
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预留总量" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.reserveInitQuantity
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预留数量" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      row.purchaseMeasurementUnit !=
                                                        null &&
                                                      row.purchaseMeasurementUnit !=
                                                        ""
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.reserveQuantity
                                                                  ? row.reserveQuantity
                                                                  : 0
                                                              ) +
                                                                _vm._s(
                                                                  row.purchaseMeasurementUnit
                                                                )
                                                            )
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                (row.reserveQuantity
                                                                  ? row.reserveQuantity
                                                                  : 0) +
                                                                  (row.purchaseMeasurementUnit
                                                                    ? row.purchaseMeasurementUnit
                                                                    : "条")
                                                              )
                                                            )
                                                          ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "备注" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(_vm._s(row.remark))
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "操作" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      scope.row.auditStatus ==
                                                      "Approved"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              attrs: { id: "" }
                                                            },
                                                            [
                                                              row.reserveStatus ==
                                                              "Reserve"
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      staticClass:
                                                                        "el-icon-close",
                                                                      attrs: {
                                                                        type:
                                                                          "primary",
                                                                        circle:
                                                                          "",
                                                                        size:
                                                                          "mini",
                                                                        title:
                                                                          "解除"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.factorychildupd(
                                                                            row,
                                                                            scope.row
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "预留单号" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(row.reserveId))]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "预留类型" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(row.reserveTypeName))]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "业务编号" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(row.saleOrderId))]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "客户名称" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(row.customerName))]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "仓位" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(_vm._s(row.reserveInventoryName))
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "创建时间" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(row.createTime))]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "申请人" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(row.applicantName))]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "凭证状态" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "right",
                                            width: "300",
                                            trigger: "hover",
                                            "open-delay": "1000"
                                          },
                                          on: {
                                            show: function($event) {
                                              return _vm.checkApprovalProcess(
                                                row,
                                                $index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("i", { staticClass: "block" }, [
                                              _vm._v(
                                                "\n                      审批人：\n                      "
                                              ),
                                              _c("em", [
                                                _vm._v(_vm._s(row.fullName))
                                              ])
                                            ]),
                                            _c(
                                              "i",
                                              {
                                                staticClass: "block mt10 mb10"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      审批时间：\n                      "
                                                ),
                                                _c("em", [
                                                  _vm._v(
                                                    _vm._s(row.approvalTime)
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c("i", { staticClass: "block" }, [
                                              _vm._v(
                                                "\n                      审批意见：\n                      "
                                              ),
                                              _c("em", [
                                                _vm._v(_vm._s(row.content))
                                              ])
                                            ])
                                          ]),
                                          _c(
                                            "el-tag",
                                            {
                                              staticClass: "pointer",
                                              attrs: {
                                                slot: "reference",
                                                type: _vm._f("statusFilter")(
                                                  row.auditStatus
                                                )
                                              },
                                              slot: "reference"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.actionsTypeHandle(
                                                    row.auditStatus
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      row.auditStatus == "Draft" ||
                                      row.auditStatus == "Rejected"
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-button", {
                                                staticClass: "el-icon-edit",
                                                attrs: {
                                                  type: "primary",
                                                  circle: "",
                                                  size: "mini",
                                                  title: "编辑"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.editHandle(
                                                      $index,
                                                      row
                                                    )
                                                  }
                                                }
                                              }),
                                              _c("el-button", {
                                                staticClass: "el-icon-check",
                                                attrs: {
                                                  type: "success",
                                                  circle: "",
                                                  size: "mini",
                                                  title: "提交审批"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.draftoutsave(
                                                      $index,
                                                      row
                                                    )
                                                  }
                                                }
                                              }),
                                              _c("el-button", {
                                                staticClass: "el-icon-delete",
                                                attrs: {
                                                  type: "danger",
                                                  circle: "",
                                                  size: "mini",
                                                  title: "删除"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.factorydel(
                                                      $index,
                                                      row
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      row.auditStatus == "Approved"
                                        ? _c(
                                            "div",
                                            { staticClass: "ending" },
                                            [
                                              row.reservetag == true
                                                ? _c("el-button", {
                                                    staticClass:
                                                      "el-icon-close",
                                                    attrs: {
                                                      type: "primary",
                                                      circle: "",
                                                      size: "mini",
                                                      title: "整单解除",
                                                      disabled: _vm.isDisabled
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.draftoutdel(
                                                          $index,
                                                          row
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "pagination_wrap fr" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 50],
                      "page-size": _vm.pageSize,
                      layout: "sizes, total, prev, pager, next",
                      total: _vm.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.editPartLoading,
              expression: "editPartLoading"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowEdit,
              expression: "isShowEdit"
            }
          ],
          staticClass: "edit_part"
        },
        [
          _c(
            "div",
            { staticClass: "clearfix mb10" },
            [
              _c(
                "div",
                { staticClass: "display_ib mr10" },
                [
                  _vm._m(0),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10 mb10",
                      attrs: {
                        disabled: !_vm.isEmptyData,
                        placeholder: "请选择"
                      },
                      on: { change: _vm.reserveTypeChange },
                      model: {
                        value: _vm.purchaseobj.reserveType,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.purchaseobj,
                            "reserveType",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "purchaseobj.reserveType"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.reserveTypeList, function(item) {
                        return _c("el-option", {
                          key: item.dictItemValue,
                          attrs: {
                            label: item.dictItemName,
                            value: item.dictItemValue
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10" },
                [
                  _vm._m(1),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10 mb10",
                      attrs: {
                        disabled: !_vm.isEmptyData,
                        placeholder: "请选择"
                      },
                      on: { change: _vm.selectwearclick },
                      model: {
                        value: _vm.purchaseobj.reserveInventory,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.purchaseobj,
                            "reserveInventory",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "purchaseobj.reserveInventory"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { label: item.name, value: item.index }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowSaleOrderId,
                      expression: "isShowSaleOrderId"
                    }
                  ],
                  staticClass: "display_ib mr10 mb10"
                },
                [
                  _c("span", { staticClass: "tag" }, [
                    !_vm.isNotRequiredSOI
                      ? _c("span", { staticClass: "red mr5" }, [_vm._v("*")])
                      : _vm._e(),
                    _vm._v("业务编号：\n        ")
                  ]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10 mb10",
                    attrs: {
                      disabled: !_vm.isEmptyData,
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.purchaseobj.saleOrderId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.purchaseobj,
                          "saleOrderId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "purchaseobj.saleOrderId"
                    }
                  })
                ],
                1
              ),
              _c("el-button", {
                staticClass: "mr10 mb10",
                attrs: {
                  size: "small",
                  type: "primary",
                  icon: "el-icon-refresh-right",
                  circle: "",
                  title: "拉取数据",
                  disabled: !_vm.isEmptyData || _vm.isDisabledPull
                },
                on: { click: _vm.pullDataHandle }
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowCustomerName,
                      expression: "isShowCustomerName"
                    }
                  ],
                  staticClass: "display_ib mr10"
                },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("客户名称：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10 mb10",
                      attrs: {
                        disabled: !_vm.isEmptyData,
                        filterable: "",
                        placeholder: "请选择"
                      },
                      on: { change: _vm.customerNameSelect },
                      model: {
                        value: _vm.purchaseobj.customerName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.purchaseobj,
                            "customerName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "purchaseobj.customerName"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.AllcustomerList, function(item) {
                        return _c("el-option", {
                          key: item.customerId,
                          attrs: {
                            label: item.customerNameCh,
                            value: item.customerId
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("el-button", {
                staticClass: "fr",
                attrs: {
                  size: "small",
                  type: "primary",
                  icon: "el-icon-plus",
                  circle: "",
                  title: "添加"
                },
                on: { click: _vm.addEditHandle }
              })
            ],
            1
          ),
          _c("upload-excel-component", {
            staticClass: "mb20",
            attrs: {
              "on-success": _vm.importExcelSuccess,
              "before-upload": _vm.importExcelBeforeUpload
            }
          }),
          _c(
            "div",
            { staticClass: "hidden" },
            [
              _c("editTable", {
                ref: "editTable",
                attrs: {
                  tableList: _vm.purchaseobj.reserveItemList,
                  total:
                    _vm.purchaseobj.reserveItemList &&
                    _vm.purchaseobj.reserveItemList.length
                      ? _vm.purchaseobj.reserveItemList.length
                      : 0,
                  tableLeaderFieldsList: _vm.leaderFieldsList,
                  tableTailFieldsList: _vm.tailFieldsList,
                  tableFieldsList: _vm.editFieldsList,
                  startPickerOptions: _vm.startPickerOptions,
                  endPickerOptions: _vm.endPickerOptions
                },
                on: {
                  deleteHandle: _vm.deleteEditItemHandle,
                  inputChange: _vm.inputChange,
                  selectChange: _vm.selectChange,
                  selectVisibleChange: _vm.selectVisibleChange
                }
              }),
              _c(
                "div",
                { staticClass: "edit_btn_wrap mt20 fr" },
                [
                  _c("el-button", { on: { click: _vm.cancelEditHandle } }, [
                    _vm._v("取消")
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      attrs: {
                        type: "primary",
                        disabled: _vm.ifchildshow != true
                      },
                      on: {
                        click: function($event) {
                          return _vm.draftsave("sv")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      attrs: {
                        type: "primary",
                        disabled: _vm.ifchildshow != true
                      },
                      on: {
                        click: function($event) {
                          return _vm.draftsave("com")
                        }
                      }
                    },
                    [_vm._v("提交审批")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条申请数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isPopDeleteDisabled },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogcommitVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogcommitVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("是否确认提交审批?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogcommitVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary", disabled: _vm.isPopSubmitDisabled },
                  on: {
                    click: function($event) {
                      return _vm.delVisibcmtclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogRelieveVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogRelieveVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("是否确认" + _vm._s(_vm.relieve) + "?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRelieveVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.relieveVisibcmtclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("预留类型：\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("预留仓位：\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }